import React, { useState } from 'react';
import { Grid, Input, Button, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegister } from '../../hooks/user';
import { usePageContext } from '../../context';

// Esquema de validación con Yup
const schema = yup.object({
  username: yup
    .string()
    .required('Username is required')
    .matches(/^[a-zA-Z0-9_]*$/, 'Solo se permiten letras, números y guiones bajos')
    .test('no-spaces', 'El nombre de usuario no debe contener espacios', (value) => !/\s/.test(value || '')),
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter') // Al menos una mayúscula
    .matches(/[0-9]/, 'Password must contain at least one number') // Al menos un número
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character'), // Al menos un carácter especial,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
}).required();

type RegisterProps = {
  onClickLogin: () => void,
  onLoginSuccessful: () => void
  onLoginError: (error:string) => void
}

export default function Register(props: RegisterProps) {
  const [loading, setLoading] = useState(false);
  const pageContext = usePageContext();
    
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    },
  });

  const onSubmit = (data:any) => {
    setLoading(true);
    useRegister({
      username: data.username,
      email: data.email,
      password: data.password,
      onSuccess: (response) => {
        console.log('Login successful:', response);
        props.onLoginSuccessful();
      },
      onError: (error) => {
        props.onLoginError(error)
      },
      onComplete: () => {
        setLoading(false);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container className="login" style={{ background: 'white', padding: 15 }}>
        <Grid item xs={12} style={{textAlign: "center", paddingBottom: 20}}>
            <Typography variant="h3">
                Registro
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Por favor ingresa tu información
            </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Username"
                fullWidth
                error={!!errors.username}
                helperText={errors.username?.message || " "}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message || " "}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contraseña"
                type="password"
                fullWidth
                error={!!errors.password}
                helperText={errors.password?.message || " "}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirmar Contraseña"
                type="password"
                fullWidth
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message || " "}
              />
            )}
          />          
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth type="submit" disabled={loading}>
            Registarse
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div style={{width: "100%", textAlign: "center", paddingTop: 16}}>
            Ya tienes una cuenta? <Button onClick={props.onClickLogin}>Iniciar sesión</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
